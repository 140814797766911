import React from 'react'
import Wrapper from '../Wrapper/Wrapper'
import './App.css'

function App() {
  return (
    <Wrapper />
  )
}

export default App